<template>
  <div class="home">  
      <v-card style="max-width: 900px; margin: 0 auto">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <h3>{{ news.title }}</h3>
          
              <div v-if="news.photo">
                <br />
                  <img class="d-block mx-auto news-photo" :src="env.url + 'foto/news/' + news.photo" :alt="news.title" />
                <br />
              </div>

              <br />

              <div class="contenuto" v-html="news.content"></div>

            </v-col>
          </v-row>
          <v-row v-if="showBackButton">
            <v-col cols="12" md="12" style="text-align: right">
              <div @click="$router.go(-1)">
                <v-btn color="blue" text><v-icon>mdi-menu-left</v-icon> Go back</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>

import env from '../env'

export default {

  name: 'NewsRead',

  components: {
      
    },

  // Data
  data () {
    return {
      env,
      news: null,
      showBackButton: (window.history.length > 1 ? true : false),
    }
  },

  mounted() {
    // Load current news content
    this.$api.getNews(this.$route.params.url).then(news => {
      this.news = news;
    });
  }

}
</script>

<style scoped>

.news-photo {
  max-width: 100%;
}

</style>
