<template>
  <div>  
        <v-card style="max-width: 900px; margin: 0 auto">
          <v-card-text>
            <v-row>
              <v-col cols="12">

                <full-news :title="title" :date="date" link="" :text="text" :photo="photo"></full-news>

              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
  </div>
</template>

<script>

import FullNews from '../components/FullNews.vue';
import meta from '../meta'

export default {


  metaInfo() {
      return {
        title: meta[this.currentSection].title,
        meta: [
          { vmid: 2, property: 'og-description', content: meta[this.currentSection].description },
          { vmid: 3, property: 'og:url', content: meta.default.ogUrl + this.$route.path },
        ],
      }
  },


  data () {
    return {
      currentSection: this.$route.name,
      title: null,
      date: null,
      text: null,
      photo: null,
    }
  },

  components: {
    FullNews
  },

  mounted() {

    // Fetch content
    this.$api.getSection(this.currentSection).then(data => {
      let content = data.values.content;       
      this.title = content.title;
      this.date = content.date;
      this.text = content.text;
      this.photo = content.photo;
    });
    
  },
}
</script>