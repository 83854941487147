let meta = {

    default: {
        title: 'Home',
        ogType: 'website',
        ogUrl: 'https://robertoraddi.it',
        ogImage: 'https://robertoraddi.it/img/facebook_meta/roberto_raddi.jpg',
        ogSiteName: 'Roberto Raddi',
        ogAdmins: '100002941145488',
        description: `I am an astrophysicist of the Astronomy and Astrophysics Group (GAA) at the Universitat Politècnica de Catalunya in Castelldefels, where I currently hold a Beatriu de Pinós fellowship. 
        My main research interests are evolved stars (i.e. white dwarfs and related science) and the fastest stars in our Milky Way (i.e. runaway and hypervelocity stars). Typically I work with large samples of ground-based and space-borne, photometric and spectroscopic observations, which I use for understanding the properties of stars I am interested in. 
        `,
    }, 

    titleTemplate: '%s | Roberto Raddi',

    /*
        SECTIONS
    */

    'CurriculumVitae': {
        title: 'Curriculum Vitae',
        description: '',
    },
    'Research': {
        title: 'Research',
        description: '',
    },
    'Publications': {
        title: 'Publications',
        description: '',
    },
    'Contacts': {
        title: 'Contacts',
        description: '',
    },
    'News': {
        title: 'News',
        description: '',
    },

}

export default meta