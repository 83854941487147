const DEV_MODE = false;

let env;

if (DEV_MODE) {

    env = {
        api: 'http://localhost/api/',
        uploadsFolder: 'http://localhost/api/uploads/',
        url: 'http://localhost/' ,
    };

} else {
    env = {
        api: '/api/',
        uploadsFolder: '/api/uploads/',      
        url: 'https://robertoraddi.it/',
    };
}


export default env