<template>
  <div id="app">

<v-app>

    <v-snackbar bottom app right v-model="darkmodeNotification" :timeout="1300">
      Dark mode {{ ($vuetify.theme.dark) ? 'on' : 'off' }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="darkmodeNotification = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  <v-navigation-drawer app v-model="menuDrawer" color="accent">

  <v-list dense nav>

    <v-row>
      <v-col class="text-center">
        <div class="profile_small_wrapper">
          <img src="@/assets/profile_small.png" class="profile_small" />
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    
      <v-list-item v-for="item in menuItems" :key="item.title" link @click="navigate(item.link)">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    <v-divider></v-divider>      

    <v-row class="d-flex justify-center px-5">
      <v-col v-for="(icon, index) in menuIcons" v-bind:key="index" class="pa-7" cols="8" md="6">
        <a :title="icon.title" :href="icon.link" target="_blank">
          <img class="menu-icon" :src="require('@/assets/' + icon.src)" :alt="icon.title" />
        </a>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-tooltip bottom nudge-top="25">
      <template v-slot:activator="{ on, tooltip1 }">

      <v-list-item v-bind="tooltip1" v-on="on">
        <v-switch flat @click="switchAction">
          <template v-slot:label>
            <v-icon>mdi-invert-colors</v-icon>
          </template>
        </v-switch>
      </v-list-item>

      </template>
      <span>Toggle dark mode</span>
    </v-tooltip>   

    </v-list>

  </v-navigation-drawer>

  <v-app-bar short app color="primary">
    <v-app-bar-nav-icon @click.stop="menuDrawer = !menuDrawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title class="header-name">Roberto Raddi</v-toolbar-title>
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main class="secondary">

    <!-- Provides the application the proper gutter -->
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
    
          <!-- If using vue-router -->
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
  
        </v-col>
      </v-row>
            
    </v-container>

  </v-main>

  <v-footer class="footer" color="primary">
      <v-row justify="center">
        <router-link to="/privacy-policy" class="link-white link-margin-right">Privacy Policy</router-link> -
        <router-link to="/cookies-policy" class="link-white link-margin-left">Cookies Policy</router-link>
        <v-col class="text-center" cols="12">
          <a class="link-white" href="https://www.sokan.it/" target="_blank">Graphic and design: Sokan Communication</a>
        </v-col>
      </v-row>
    </v-footer>

    <go-top right="30px" bottom="70px" bg-color="RGB(0.3, 0.3, 0.3, 0.4)"></go-top>

</v-app>

  </div>
</template>

<script>

import GoTop from '@inotom/vue-go-top';
import meta from '/src/meta'

export default {
   
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: meta.default.title,
      // all titles will be injected into this template
      titleTemplate: meta.titleTemplate,
      meta: [
        { property: 'og:title', content: meta.default.title },
        { property: 'og:type', content: meta.default.ogType },
        { vmid: 3, property: 'og:url', content: meta.default.ogUrl },
        { property: 'og:image', content: meta.default.ogImage },
        { property: 'og:site_name', content: meta.default.ogSiteName },
        { property: 'og:admins', content: meta.default.ogAdmins },
        { vmid: 2, property: 'og:description', content: meta.default.description },
      ]
    },

    // Data
    data () {
      return {
        darkmodeNotification: false,
        tooltip1: null,
        menuDrawer: null,
        menuItems: [
          { title: 'HOME', icon: 'mdi-home', link: 'Home' },
          { title: 'NEWS', icon: 'mdi-newspaper-variant-multiple-outline', link: 'News' },
          { title: 'CURRICULUM VITAE', icon: 'mdi-file-document-outline', link: 'CurriculumVitae' },
          { title: 'RESEARCH', icon: 'mdi-book-open-variant', link: 'Research' },
          { title: 'PUBLICATIONS', icon: 'mdi-post-outline', link: 'Publications' },
          { title: 'CONTACTS', icon: 'mdi-phone', link: 'Contacts' },
          /*{ title: 'OUTREACH', icon: 'mdi-link-variant', link: 'Outreach' },*/
        ],

        menuIcons: [

          {
            title: 'Nasa-ads',
            link: 'https://ui.adsabs.harvard.edu/search/fq=%7B!type%3Daqp%20v%3D%24fq_database%7D&fq_database=database%3A%20astronomy&q=author%3A(%22raddi%2C%20roberto%22)&sort=date%20desc%2C%20bibcode%20desc&p_=0',
            src: 'nasa-ads.png',
          },

          {
            title: 'Orcid',
            link: 'https://orcid.org/0000-0002-9090-9191',
            src: 'orcid.png',
          },

          {
            title: 'Publons',
            link: 'https://publons.com/researcher/2086272/roberto-raddi/',
            src: 'publons.png',
          },

          {
            title: 'Scopus',
            link: 'https://www.scopus.com/authid/detail.uri?authorId=55256729500',
            src: 'scopus.png',
          },


        ],

        right: null,
      }
    },

    // Methods
    methods: {

      navigate(route) {

        if (this.$route.name == route)
          return;

        if (route == 'News') {
          this.$router.push({ path: '/news/1/' });
        } else {
          this.$router.push({ name: route });
        }

      },

      switchAction() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        this.darkmodeNotification = true;
      },
    },

    // Components
    components: {
      GoTop
    },

}

</script>

<style>

/*
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap"); 

.v-application {
  font-family: 'Merriweather Sans', sans-serif !important;
}
*/

.fade-enter-active {
  transition: all .5s ease;
}
.fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(50px);
  opacity: 0;
}
.fade-enter {
  opacity: 0;
}

img {
  max-width: 100%;
}

.menu-icon {
  margin: 0 auto;
  display: block;
  width: 80%;
}

.link-white {
  color: #fff !important;
  text-decoration: none !important;
}

.link-margin-right {
  margin-right: 4px;
}

.link-margin-left {
  margin-left: 4px;
}

</style>