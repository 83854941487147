import env from '../env'
import axios from 'axios'

// PHP Api base URL
const api_url = env.api;

const api = {

    getSection: async section => {
        return axios
            .post(api_url + 'Sections/', {
                funct: 'getContent',
                section
            })
            .then(response => {
                return response.data;
            });
    },

    getHomeNews: async () => {
        return axios.post(api_url + 'News/', { funct: 'getHomeNews' })
            .then(response => {
                return response.data;
            });
    },

    getNews: async url => {
        return axios.post(api_url + 'News/', { funct: 'getNews', url })
            .then(response => {
                return response.data;
            });
    },

    getNewsByPage: async page => {
        return axios.post(api_url + 'News/', { funct: 'getNewsByPage', page })
            .then(response => {
                return response.data;
                /*

                    numberOfNews: n
                    numberOfPages: n
                    newsPerPage: n
                    currentPage: n
                    news: {
                        title: '',
                        date: 'nn/nn/nnnn nn:nn',
                        url: 'x'
                    }

                */
            });
    },

};

export default api