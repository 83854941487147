<template>
    <div>


        <v-card flat @click="goNews('/news/read/'+link)" class="link">

            <v-card-title class="title">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <div class="news-img-div" :style="'background-image: url(' + env.url + 'foto/news/' + photo + ')'">
                        </div>
                    </v-col>
                    <v-col cols="12" md="8">
                        <div v-html="text"></div>
                    </v-col>
                </v-row>
            </v-card-text>
            <!--
            <v-list-item three-line>
                <v-list-item-avatar
                    tile
                    size="200"
                    v-if="photo"
                >               
                    <img 
                        :src="env.url + 'foto/news/' + photo"
                        :alt="title"
                    >
                </v-list-item-avatar>
                <v-list-item-content class="news-content pt-0">
                    <v-list-item-title class="text-h5 mb-1">
                        {{title}}
                    </v-list-item-title>
                    <div v-html="text"></div>
                </v-list-item-content>


            </v-list-item>
            -->

            <v-card-actions>
                <v-spacer></v-spacer>
                <div @click="goNews('/news/read/'+link)" class="text-right">
                    <v-btn color="blue" text><v-icon>mdi-menu-right</v-icon> Read more</v-btn>
                </div>
            </v-card-actions>
        </v-card>
        
        <br />

    </div>
</template>



<script>

//import router from '../router'
import env from '../env'

export default {
  name: 'HomeNews',
  props: {
    title: String,
    link: String,
    date: String,
    text: String,
    photo: String,
  },

  data() {
      return {
          env
      }
  },

  // Methods
  methods: {
    goNews(url) {           
        this.$router.push({ path: url });
        }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.news-content {
    color: #000;
}

.news-img-div {    
    width: 100%;
    min-height: 200px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 768px) {
    .news-img-div {
        min-height: 300px;
    }
}

.title {
    color: #000;
    font-weight: bold !important;
}

.date {
    text-align: left;
    
}

.link {
    cursor: pointer;
}

</style>
