<template>
    <div>
        <div>
            <span class="title">
                {{ title }}
            </span>
            <div v-if="photo">
                <br />
                <img class="d-block mx-auto full-news-photo" :src="env.url + '/foto/news/' + photo" />
            </div>
           
            <br />
            <div v-html="text"></div>
        </div>
        <br /><br />
    </div>
</template>



<script>

//import router from '../router'
import env from '../env'

export default {
  name: 'HomeNews',
  props: {
    title: String,
    link: String,
    date: String,
    text: String,
    photo: String,
  },

  data() {
      return {
        env,
      }
  },

  // Methods
  methods: {
    goNews(url) {           
        this.$router.push({ path: url });
        }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
    font-size: 250%;
    font-weight: bold !important;
}

.date {
    text-align: left;
    
}

.link {
    cursor: pointer;
}

.full-news-photo {
    max-width: 100%;
}

</style>
