<template>
  <div class="home">  
        <v-card style="max-width: 900px; margin: 0 auto">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" style="text-align: center">
                <img style="width: 100%; max-width: 500px" src="@/assets/profile.jpg" />
              </v-col>
              <v-col cols="12" md="6" v-html="text">
                <!-- Content -->

                <!--
                  <h1><strong>Roberto Raddi</strong></h1>
                  <br />
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pharetra tempus sodales. Mauris pulvinar, erat sit amet vulputate gravida, neque eros consequat justo, aliquet dictum urna nisl ac nunc.</p>
                  <p>Ut faucibus pellentesque velit, ac blandit leo accumsan nec. Sed ornare mi vel lacus rutrum, non semper justo bibendum. Vivamus sagittis efficitur elit, a imperdiet magna vehicula eget. In at tempus sem. Cras fringilla lectus vel cursus gravida.</p>
                  <p>&nbsp;</p>
                  <p>Vivamus sagittis efficitur eli:</p>
                  <ul>
                  <li>Proin pellentesque velit et purus tristique lacinia.</li>
                  <li>Vivamus in nulla ut nisi tempor scelerisque ut sed felis.</li>
                  <li>Vestibulum in cursus diam.</li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>Vivamus sagittis efficitur elit, a imperdiet magna vehicula eget. In at tempus sem.</p>
                -->

                <!-- Content -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <br /><br />

        <v-card style="max-width: 900px; margin: 0 auto">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                LAST NEWS
                <br /><br />
                <!-- Last 5 news -->
                <home-news v-for="(item, index) in lastNews" v-bind:key="index" :title="item.title" :date="item.date" :link="item.link" :text="item.text" :photo="item.photo"></home-news>
                <!-- Last 5 news -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

  </div>
</template>

<script>

import HomeNews from '../components/HomeNews.vue';
import meta from '../meta'

export default {
  name: 'Home',

  components: {
      HomeNews
    },

  // Data
  data () {
    return {
      meta,
      lastNews: null,
      right: null,
      text: '',
    }
  },

  mounted() {

    // Fetch content
    this.$api.getSection('Home').then(data => {          
      this.text = data.values.content.text;
    });

    // Load last news
    this.$api.getHomeNews().then(news => {
      this.lastNews = news;
    });
  }

}
</script>
