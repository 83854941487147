import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CurriculumVitae from '../views/CurriculumVitae.vue'
import Research from '../views/Research.vue'
import Publications from '../views/Publications.vue'
import Outreach from '../views/Outreach.vue'
import NewsRead from '../views/NewsRead.vue'
import News from '../views/News.vue'
import Contacts from '../views/Contacts.vue'
import Privacy from '../views/Privacy.vue'
import Cookies from '../views/Cookies.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/news/:page', name: 'News', component: News },
  { path: '/curriculum-vitae', name: 'CurriculumVitae', component: CurriculumVitae },
  { path: '/research', name: 'Research', component: Research },
  { path: '/publications', name: 'Publications', component: Publications },
  { path: '/outreach', name: 'Outreach', component: Outreach },
  { path: '/news/read/:url', name: 'Read', component: NewsRead },
  { path: '/contacts', name: 'Contacts', component: Contacts },  
  { path: '/privacy-policy', name: 'Privacy', component: Privacy }, 
  { path: '/cookies-policy', name: 'Cookies', component: Cookies }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
