import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@/scss/variables.scss'
import '@/assets/style.css'
import '@/assets/background-img.png'
import colors from 'vuetify/lib/util/colors'
import env from './env'
import api from './api'
import VueMeta from 'vue-meta'

Vue.prototype.$env = env;
Vue.prototype.$api = api;

Vue.use(Vuetify)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: false
})

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#0065B4',
        secondary: colors.blueGrey.lighten5,
        accent: '#005397'
      },
      dark: {
        primary: '#1e1e1e',
        secondary: colors.blueGrey.darken4,
        accent: '#1e1e1e',
      },
    },
  },
}

const vuetify = new Vuetify(opts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
