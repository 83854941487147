<template>
  <div class="home">  
      <v-card style="max-width: 900px; margin: 0 auto">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
                <!-- News -->
                <full-news v-for="(item, index) in news.news" v-bind:key="index" :title="item.title" :date="item.date" :link="item.link" :text="item.text" :photo="item.photo"></full-news>

                <!-- Pagination -->
                <v-pagination
                v-model="news.currentPage"
                :length="news.numberOfPages"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :total-visible="7"
                ></v-pagination>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </div>
</template>

<style>

.v-pagination__navigation .theme--light {
    color: #0065B4 !important;
}

</style>

<script>

import FullNews from '../components/FullNews.vue';
import meta from '../meta'

export default {

  metaInfo() {
      return {
        title: meta[this.currentSection].title,
        meta: [
          { vmid: 2, property: 'og-description', content: meta[this.currentSection].description },
          { vmid: 3, property: 'og:url', content: meta.default.ogUrl + this.$route.path },
        ],
      }
  },

  name: 'News',

  components: {
      FullNews
    },

  // Data
  data () {
    return {

      currentSection: 'News',
      
      news: {
          currentPage: 1,
          numberOfNews: 0,
          numberOfPages: 0,
          newsPerPage: 0,
          news: null,
      },
        
    }
  },

  watch: {
    'news.currentPage': {
        handler: function(newPage, oldPage) {
            this.$router.push({ path: '/news/'+newPage+'/' });
            this.$api.getNewsByPage(newPage).then(response => {
              this.news = response;
            });
        }
    }
  },

  mounted() {

    this.currentSection = 'News';

    // Load total number of pages and 10 news
    this.$api.getNewsByPage(this.$route.params.page).then(response => {
        this.news = response;
    });
  },

}
</script>
